// @ts-nocheck
import { FFormGroup, T } from '@/components';
import {
  Button,
  Classes,
  Popover,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import styles from '../Attachments/UploadAttachmentButton.module.scss';
import { Field } from 'formik';
import clsx from 'classnames';
import { UploadLogoPopoverContent } from './UploadLogoPopoverContent';

function UploadAttachmentButtonButtonContentField() {
  return (
    <Field name={'logo'}>
      {({ form: { setFieldValue }, field: { value } }) => (
        <UploadLogoPopoverContent
          value={value}
          onChange={(changedValue) => {
            setFieldValue('logo', changedValue);
          }}
          maxFiles={1}
          multiple={false}
        />
      )}
    </Field>
  );
}

export function UpdateLogoButton() {
  return (
    <FFormGroup
      name={'logo'}
      label={<T id={'organization_logo'} />}
      inline={false}
      fastField={true}
    >
      <Popover
        interactionKind={PopoverInteractionKind.CLICK}
        popoverClassName={clsx(styles.popover, Classes.POPOVER_CONTENT_SIZING)}
        placement={'top-start'}
        content={<UploadAttachmentButtonButtonContentField />}
      >
        <Button className={styles.attachmentButton}>Upload Logo</Button>
      </Popover>
    </FFormGroup>
  );
}
