// @ts-nocheck
import React from 'react';
import { useHistory } from 'react-router';
import {
  Navbar,
  NavbarGroup,
  Button,
  Classes,
  Tooltip,
  Position,
} from '@blueprintjs/core';
import { FormattedMessage as T, Icon, Hint, If } from '@/components';

import DashboardBreadcrumbs from '@/components/Dashboard/DashboardBreadcrumbs';
import DashboardBackLink from '@/components/Dashboard/DashboardBackLink';

import withUniversalSearchActions from '@/containers/UniversalSearch/withUniversalSearchActions';
import withDashboardActions from '@/containers/Dashboard/withDashboardActions';
import withDashboard from '@/containers/Dashboard/withDashboard';

import QuickNewDropdown from '@/containers/QuickNewDropdown/QuickNewDropdown';
import {
  DashboardQuickSearchButton,
} from './_components';
import { compose } from '@/utils';

/**
 * Dashboard topbar.
 */
function DashboardTopbar({
  // #withDashboard
  pageTitle,
  editViewId,
  pageHint,

  // #withDashboardActions
  toggleSidebarExpand,

  // #withDashboard
  sidebarExpended,

  // #withGlobalSearch
  openGlobalSearch,
}) {
  const history = useHistory();

  const handlerClickEditView = () => {
    history.push(`/custom_views/${editViewId}/edit`);
  };

  const handleSidebarToggleBtn = () => {
    toggleSidebarExpand();
  };

  return (
    <div class="dashboard__topbar" data-testId={'dashboard-topbar'}>
      <div class="dashboard__topbar-left">

        <div class="dashboard__title">
          <h1>{pageTitle}</h1>

          <If condition={pageHint}>
            <div class="dashboard__hint">
              <Hint content={pageHint} />
            </div>
          </If>

          <If condition={editViewId}>
            <Button
              className={Classes.MINIMAL + ' button--view-edit'}
              icon={<Icon icon="pen" iconSize={13} />}
              onClick={handlerClickEditView}
            />
          </If>
        </div>

        <div class="dashboard__breadcrumbs">
          <DashboardBreadcrumbs />
        </div>
        <DashboardBackLink />
      </div>

      <div class="dashboard__topbar-right">
        <Navbar class="dashboard__topbar-navbar">
          <NavbarGroup className="dashboard__topbar-navbar-group">
            <Tooltip
              content={<T id={'quick_find'} />}
              position={Position.BOTTOM}
            >
              <DashboardQuickSearchButton
                onClick={() => openGlobalSearch(true)}
              />
            </Tooltip>
            <Tooltip
              content={<T id={'notifications'} />}
              position={Position.BOTTOM}
            >
              <Button
                className={'button--notifications'}
                icon={<Icon icon={'notification-24'} iconSize={20} />}
              />
            </Tooltip>
            <Tooltip
              content={<T id={'quick_new'} />}
              position={Position.BOTTOM}
            >
              <QuickNewDropdown />
            </Tooltip>
          </NavbarGroup>
        </Navbar>
      </div>
    </div>
  );
}

export default compose(
  withUniversalSearchActions,
  withDashboard(({ pageTitle, pageHint, editViewId, sidebarExpended }) => ({
    pageTitle,
    editViewId,
    sidebarExpended,
    pageHint,
  })),
  withDashboardActions,
)(DashboardTopbar);
