// @ts-nocheck
import React from 'react';
import styled from 'styled-components';

export function Card({ className, children }) {
  return <CardRoot className={className}>{children}</CardRoot>;
}

const CardRoot = styled.div`
  padding: 15px;
  margin: 15px;
  background: #fff;
  border: 1px solid #d2dce2;
`;

export const CardFooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
  border-top: 1px solid #e0e7ea;
  margin-top: 30px;

  .bp4-button {
    min-width: 70px;

    + .bp4-button {
      margin-left: 10px;
    }
  }

  .bp4-button:first-child {
    background-color: #f5f5f5; 
    color: #FF4D4F; 
  }

  .bp4-button:last-child {
    background-color: #1890FF; 
  }
`;
