// @ts-nocheck
import { Button, Popover, Menu, Position } from '@blueprintjs/core';

import { FormattedMessage as T } from '@/components';

import withCurrentOrganization from '@/containers/Organization/withCurrentOrganization';
import { useAuthenticatedAccount } from '@/hooks/query';
import { compose, firstLettersArgs } from '@/utils';
import DashboardTopbarUser from '@/components/Dashboard/TopbarUser';
import { DashboardHamburgerButton } from '@/components/Dashboard/DashboardTopbar/_components';
import withDashboard from '@/containers/Dashboard/withDashboard';
import withDashboardActions from '../withDashboardActions';
import { SidebarMenu } from './SidebarMenu';
import { useMainSidebarMenu } from './hooks';

// Popover modifiers.
const POPOVER_MODIFIERS = {
  offset: { offset: '28, 8' },
};

/**
 * Sideabr head.
 */
function SidebarHeadJSX({
  // #withCurrentOrganization
  organization,

  // #withDashboard
  sidebarExpended,

  // #withDashboardActions
  toggleSidebarExpand,
}) {
  const menu = useMainSidebarMenu();

  // Retrieve authenticated user information.
  const { data: user } = useAuthenticatedAccount();

  const handleSidebarToggleBtn = () => {
    toggleSidebarExpand();
  };

  return (
    <>
      <div className="sidebar__head">
        <div className="sidebar__head-section-1">
          <div class="dashboard__topbar-user">
            <DashboardTopbarUser />
          </div>
          <div className="sidebar__head-organization">
            <Popover
              modifiers={POPOVER_MODIFIERS}
              boundary={'window'}
              content={
                <Menu className={'menu--dashboard-organization'}>
                  <div class="org-item">
                    <div class="org-item__logo">
                      {firstLettersArgs(
                        ...(organization.name || '').split(' '),
                      )}{' '}
                    </div>
                    <div class="org-item__name">{organization.name}</div>
                  </div>
                </Menu>
              }
              position={Position.TOP_RIGHT}
              minimal={true}
            >
              <Button
                className="title"
              >
                {organization.name}
              </Button>
            </Popover>
            <span class="subtitle">{user.full_name}</span>
          </div>
        </div>

          <div class="dashboard__topbar-sidebar-toggle">
              <DashboardHamburgerButton
                onClick={handleSidebarToggleBtn}
                sidebarExpanded={sidebarExpended}
              />
          </div>

      </div>
      <div className={`${sidebarExpended ? 'sidebar__menu' : 'sidebar__menu-expanded'}`}>
        <SidebarMenu menu={menu} sidebarExpended={sidebarExpended} />
      </div>
    </>
  );
}

export const SidebarHead = compose(
  withCurrentOrganization(({ organization }) => ({ organization })),
  withDashboard(({ sidebarExpended }) => ({
    sidebarExpended,
  })),
  withDashboardActions,
)(SidebarHeadJSX);
