// @ts-nocheck
import React from 'react';
import intl from 'react-intl-universal';
import styled from 'styled-components';
import { Intent, Button, Menu, MenuItem, MenuDivider } from '@blueprintjs/core';

import { SwitchFieldCell } from '@/components/DataTableCells';
import { safeInvoke } from '@/utils';

/**
 * Notification accessor.
 */
export const NotificationAccessor = (row) => {
  return (
    <span className="notification">
      <NotificationLabel>{row.notification_label}</NotificationLabel>
      <NotificationDescription>
        {row.notification_description}
      </NotificationDescription>
    </span>
  );
};

/**
 * SMS notification message cell.
 */
export const SMSMessageCell = ({
  payload: { onEditMessageText },
  row: { original },
}) => (
  <MessageBoxContainer>
    <MessageBox>{original.sms_message}</MessageBox>
    <MessageBoxActions>
      <Button
        minimal={true}
        small={true}
        intent={Intent.NONE}
        onClick={() => safeInvoke(onEditMessageText, original)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M17.1875 16.3281H2.8125C2.4668 16.3281 2.1875 16.6074 2.1875 16.9531V17.6562C2.1875 17.7422 2.25781 17.8125 2.34375 17.8125H17.6562C17.7422 17.8125 17.8125 17.7422 17.8125 17.6562V16.9531C17.8125 16.6074 17.5332 16.3281 17.1875 16.3281ZM5.0332 14.6875C5.07227 14.6875 5.11133 14.6836 5.15039 14.6777L8.43555 14.1016C8.47461 14.0938 8.51172 14.0762 8.53906 14.0469L16.8184 5.76758C16.8365 5.74951 16.8508 5.72805 16.8606 5.70442C16.8704 5.68079 16.8755 5.65546 16.8755 5.62988C16.8755 5.6043 16.8704 5.57897 16.8606 5.55535C16.8508 5.53172 16.8365 5.51026 16.8184 5.49219L13.5723 2.24414C13.5352 2.20703 13.4863 2.1875 13.4336 2.1875C13.3809 2.1875 13.332 2.20703 13.2949 2.24414L5.01562 10.5234C4.98633 10.5527 4.96875 10.5879 4.96094 10.627L4.38477 13.9121C4.36577 14.0167 4.37255 14.1244 4.40454 14.2258C4.43654 14.3273 4.49276 14.4193 4.56836 14.4941C4.69727 14.6191 4.85938 14.6875 5.0332 14.6875Z"
            fill="white"
          />
        </svg>
      </Button>
    </MessageBoxActions>
  </MessageBoxContainer>
);

/**
 * Context menu of SMS notification messages.
 */
export function ActionsMenu({
  payload: { onEditMessageText, onEnableNotification, onDisableNotification },
  row: { original },
}) {
  return (
    <Menu>
      <MenuItem
        text={intl.get('sms_notifications.edit_message_text')}
        onClick={() => safeInvoke(onEditMessageText, original)}
      />
      <MenuDivider />
      {!original.is_notification_enabled ? (
        <MenuItem
          text={intl.get('sms_notifications.enable_notification')}
          onClick={() => safeInvoke(onEnableNotification, original)}
        />
      ) : (
        <MenuItem
          text={intl.get('sms_notifications.disable_notification')}
          onClick={() => safeInvoke(onDisableNotification, original)}
        />
      )}
    </Menu>
  );
}

/**
 * Retrieve SMS notifications messages table columns
 * @returns
 */
export function useSMSIntegrationTableColumns({ onSwitchChange }) {
  return React.useMemo(
    () => [
      {
        id: 'notification',
        Header: intl.get('sms_messages.column.notification'),
        accessor: NotificationAccessor,
        className: 'notification',
        width: '180',
        disableSortBy: true,
      },
      {
        Header: intl.get('sms_messages.column.service'),
        accessor: 'module_formatted',
        className: 'service',
        width: '80',
        disableSortBy: true,
      },
      {
        Header: intl.get('sms_messages.column.message'),
        accessor: 'sms_message',
        Cell: SMSMessageCell,
        className: 'sms_message',
        width: '180',
        disableSortBy: true,
      },
      {
        Header: intl.get('sms_messages.column.auto'),
        accessor: 'is_notification_enabled' ? `${'is_notification_enabled'} Active` : `${'is_notification_enabled'} Inactive`,
        Cell: SwitchFieldCell,
        className: 'is_notification_enabled',
        disableResizing: true,
        disableSortBy: true,
        width: '80',
        onSwitchChange,
      },
    ],
    [onSwitchChange],
  );
}

const NotificationLabel = styled.div`
  font-weight: 500;
`;

const NotificationDescription = styled.div`
  font-size: 14px;
  margin-top: 6px;
  display: block;
  opacity: 0.75;
`;

const MessageBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const MessageBox = styled.div`
  padding: 10px;
  background-color: #fbfbfb;
  border: 1px dashed #dcdcdc;
  font-size: 14px;
  line-height: 1.45;
`;

const MessageBoxActions = styled.div`
  margin-top: 2px;

  button{
      font-size: 12px;
  }

  .bp4-button svg{
    display: flex;
    align-items: center;
    background-color: #1890FF;
    height: 30px;
    width: 30px;
    padding: 5px;
    border-radius: 50%;
  }
`;
