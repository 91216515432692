// @ts-nocheck
import React from 'react';

export function MenuItemLabel({ text, sidebarExpended }) {
  return sidebarExpended ? (
    <span class="bp4-menu-item-labeler">{text}</span>
  ) : (
    <span class="bp4-menu-item-separator">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="49"
        height="2"
        viewBox="0 0 49 2"
        fill="none"
      >
        <path d="M0.239258 1H48.7605" stroke="white" stroke-width="0.5" />
      </svg>
    </span>
  );
}
