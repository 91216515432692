// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Form } from 'formik';
import { Button, FormGroup, Intent } from '@blueprintjs/core';
import { TimezonePicker } from '@blueprintjs/timezone';
import { ErrorMessage, FastField } from 'formik';
import { useHistory } from 'react-router-dom';

import {
  FieldRequiredHint,
  FormattedMessage as T,
  FFormGroup,
  FInputGroup,
  FSelect,
  Row,
  Stack,
  Col,
  Paper,
  CardFooterActions,
} from '@/components';
import { inputIntent } from '@/utils';
import { CLASSES } from '@/constants/classes';
import { getAllCurrenciesOptions } from '@/constants/currencies';
import { getFiscalYear } from '@/constants/fiscalYearOptions';
import { getLanguages } from '@/constants/languagesOptions';
import { useGeneralFormContext } from './GeneralFormProvider';
import { getAllCountries } from '@/utils/countries';

import { shouldBaseCurrencyUpdate } from './utils';
import { UpdateLogoButton } from '@/containers/UpdateLogo/UpdateLogoButton';
import { getZatcaMode } from '@/constants/baseUrlOptions';

const Countries = getAllCountries();
/**
 * Preferences general form.
 */
export default function PreferencesGeneralForm({ isSubmitting }) {
 
  const history = useHistory();

  const FiscalYear = getFiscalYear();
  const Languages = getLanguages();
  const Currencies = getAllCurrenciesOptions();
  const ZatcaMode = getZatcaMode();

  const { dateFormats, baseCurrencyMutateAbility } = useGeneralFormContext();

  const baseCurrencyDisabled = baseCurrencyMutateAbility.length > 0;

  // Handle close click.
  const handleCloseClick = () => {
    history.go(-1);
  };

  return (
    <Form>
      <PreferencesContainer>
      <Row style={{ 'justify-content': 'space-around' }}>
        <CustomCol xs={3}>
        <Stack spacing={20} width={'300px'}>
          {/* ---------- Organization name ----------  */}
          <FFormGroup
            name={'name'}
            label={<T id={'organization_name'} />}
            labelInfo={<FieldRequiredHint />}
            inline={false}
            helperText={<T id={'shown_on_sales_forms_and_purchase_orders'} />}
            fastField={true}
          >
            <FInputGroup medium={'true'} name={'name'} fastField={true} />
          </FFormGroup>

          {/* ---------- Organization Tax Number ----------  */}
          <FFormGroup
            name={'tax_number'}
            label={<T id={'organization_tax_number'} />}
            inline={false}
            helperText={<T id={'shown_on_sales_forms_and_purchase_orders'} />}
            fastField={true}
          >
            <FInputGroup medium={'true'} name={'tax_number'} fastField={true} />
          </FFormGroup>

          {/* ---------- Industry ----------  */}
          <FFormGroup
            name={'industry'}
            label={<T id={'organization_industry'} />}
            inline={false}
            fastField={true}
          >
            <FInputGroup name={'industry'} medium={'true'} fastField={true} />
          </FFormGroup>

          {/* ---------- UUID ----------  */}
          <FFormGroup
            name={'uuid'}
            label={<T id={'uuid'} />}
            inline={false}
            fastField={true}
          >
            <FInputGroup
              name={'uuid'}
              medium={'true'}
              fastField={true}
              disabled={true}
            />
          </FFormGroup>

          {/* ---------- Custom ID ----------  */}
          <FFormGroup
            name={'custom_id'}
            label={<T id={'custom_id'} />}
            inline={false}
            fastField={true}
          >
            <FInputGroup name={'custom_id'} medium={'true'} fastField={true} />
          </FFormGroup>

          {/* ---------- Model ----------  */}
          <FFormGroup
            name={'model'}
            label={<T id={'model'} />}
            inline={false}
            fastField={true}
          >
            <FInputGroup name={'model'} medium={'true'} fastField={true} />
          </FFormGroup>

          {/* ---------- CRN Number ----------  */}
          <FFormGroup
            name={'CRN_number'}
            label={<T id={'crn_number'} />}
            inline={false}
            fastField={true}
          >
            <FInputGroup name={'CRN_number'} medium={'true'} fastField={true} />
          </FFormGroup>
        </Stack>
        </CustomCol>
        <CustomCol xs={3}>
        <Stack spacing={20} width={'300px'}>
          {/* ---------- Location ---------- */}
          <FFormGroup
            name={'location'}
            label={<T id={'business_location'} />}
            inline={false}
            fastField={true}
          >
            <FSelect
              name={'location'}
              items={Countries}
              valueAccessor={'countryCode'}
              labelAccessor={'countryCode'}
              textAccessor={'name'}
              placeholder={<T id={'select_business_location'} />}
              popoverProps={{ minimal: true }}
              fastField={true}
            />
          </FFormGroup>
          <FFormGroup
            name={'location_city'}
            label={<T id={'city'} />}
            inline={false}
            fastField={true}
          >
            <FInputGroup
              name={'location_city'}
              medium={'true'}
              fastField={true}
            />
          </FFormGroup>
          <FFormGroup
            name={'location_city_subdivision'}
            label={<T id={'city_subdivision'} />}
            inline={false}
            fastField={true}
          >
            <FInputGroup
              name={'location_city_subdivision'}
              medium={'true'}
              fastField={true}
            />
          </FFormGroup>
          <FFormGroup
            name={'location_street'}
            label={<T id={'street'} />}
            inline={false}
            fastField={true}
          >
            <FInputGroup
              name={'location_street'}
              medium={'true'}
              fastField={true}
            />
          </FFormGroup>
          <FFormGroup
            name={'location_plot_identification'}
            label={<T id={'plot_identification'} />}
            inline={false}
            fastField={true}
          >
            <FInputGroup
              name={'location_plot_identification'}
              medium={'true'}
              fastField={true}
            />
          </FFormGroup>
          <FFormGroup
            name={'location_building'}
            label={<T id={'building'} />}
            inline={false}
            fastField={true}
          >
            <FInputGroup
              name={'location_building'}
              medium={'true'}
              fastField={true}
            />
          </FFormGroup>
          <FFormGroup
            name={'location_postal_zone'}
            label={<T id={'postal_zone'} />}
            inline={false}
            fastField={true}
          >
            <FInputGroup
              name={'location_postal_zone'}
              medium={'true'}
              fastField={true}
            />
          </FFormGroup>
        </Stack>
        </CustomCol>

        <CustomCol xs={3}>
        <Stack spacing={20} width={'300px'}>
          <UpdateLogoButton />

          {/* ----------  Base currency ----------  */}
          <FFormGroup
            name={'base_currency'}
            baseCurrencyDisabled={baseCurrencyDisabled}
            label={<T id={'base_currency'} />}
            labelInfo={<FieldRequiredHint />}
            inline={false}
            helperText={
              <T
                id={
                  'you_can_t_change_the_base_currency_as_there_are_transactions'
                }
              />
            }
            fastField={true}
            shouldUpdate={shouldBaseCurrencyUpdate}
          >
            <FSelect
              name={'base_currency'}
              items={Currencies}
              valueAccessor={'key'}
              textAccessor={'name'}
              labelAccessor={'key'}
              placeholder={<T id={'select_base_currency'} />}
              popoverProps={{ minimal: true }}
              disabled={baseCurrencyDisabled}
              fastField={true}
              shouldUpdate={shouldBaseCurrencyUpdate}
              baseCurrencyDisabled={baseCurrencyDisabled}
            />
          </FFormGroup>

          {/* --------- Fiscal Year ----------- */}
          <FFormGroup
            name={'fiscal_year'}
            label={<T id={'fiscal_year'} />}
            labelInfo={<FieldRequiredHint />}
            inline={false}
            helperText={<T id={'for_reporting_you_can_specify_any_month'} />}
            fastField={true}
          >
            <FSelect
              name={'fiscal_year'}
              items={FiscalYear}
              valueAccessor={'key'}
              textAccessor={'name'}
              placeholder={<T id={'select_fiscal_year'} />}
              popoverProps={{ minimal: true }}
              fastField={true}
            />
          </FFormGroup>

          {/* ---------- Language ---------- */}
          <FormGroup
            name={'language'}
            label={<T id={'language'} />}
            labelInfo={<FieldRequiredHint />}
            inline={false}
            fastField={true}
          >
            <FSelect
              name={'language'}
              items={Languages}
              valueAccessor={'value'}
              textAccessor={'name'}
              placeholder={<T id={'select_language'} />}
              popoverProps={{ minimal: true }}
              fastField={true}
            />
          </FormGroup>

          {/* ----------  Time zone ----------  */}
          <FastField name={'timezone'}>
            {({ form, field: { value }, meta: { error, touched } }) => (
              <FormGroup
                label={<T id={'time_zone'} />}
                labelInfo={<FieldRequiredHint />}
                inline={false}
                className={classNames(
                  'form-group--time-zone',
                  CLASSES.FORM_GROUP_LIST_SELECT,
                  CLASSES.FILL,
                )}
                intent={inputIntent({ error, touched })}
                helperText={<ErrorMessage name="timezone" />}
              >
                <TimezonePicker
                  value={value}
                  onChange={(timezone) => {
                    form.setFieldValue('timezone', timezone);
                  }}
                  valueDisplayFormat="composite"
                  placeholder={<T id={'select_time_zone'} />}
                />
              </FormGroup>
            )}
          </FastField>

          {/* --------- Data format ----------- */}
          <FFormGroup
            name={'date_format'}
            label={<T id={'date_format'} />}
            labelInfo={<FieldRequiredHint />}
            inline={false}
            helperText={<ErrorMessage name="date_format" />}
            fastField={true}
          >
            <FSelect
              name={'date_format'}
              items={dateFormats}
              valueAccessor={'key'}
              textAccessor={'label'}
              placeholder={<T id={'select_date_format'} />}
              popoverProps={{ minimal: true }}
              fastField={true}
            />
          </FFormGroup>

          {/* ---------- ZATCA Mode ---------- */}
          <FormGroup
            name={'zatca_mode'}
            label={<T id={'zatca_mode'} />}
            labelInfo={<FieldRequiredHint />}
            inline={false}
            fastField={true}
          >
            <FSelect
              name={'zatca_mode'}
              items={ZatcaMode}
              valueAccessor={'value'}
              textAccessor={'name'}
              placeholder={<T id={'select_zatca_mode'} />}
              popoverProps={{ minimal: true }}
              fastField={true}
            />
          </FormGroup>
        </Stack>
        </CustomCol>
    
      </Row>
      </PreferencesContainer>

      <CardFooterActions>
        <Button onClick={handleCloseClick}>
          <T id={'close'} />
        </Button>
        <Button loading={isSubmitting} intent={Intent.PRIMARY} type="submit">
          <T id={'save'} />
        </Button>
      </CardFooterActions>
    </Form>
  );
}

const PreferencesContainer = styled(Paper)`
  padding: 10px 10px 20px 0px;
  flex: 1 0;
  border: none; !important
`;

const CustomCol = styled(Col)`
  padding: 0 0 0 20px !important;
`;
