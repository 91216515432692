// @ts-nocheck
import React from 'react';
import { Button, Classes } from '@blueprintjs/core';
import { useGetUniversalSearchTypeOptions } from '@/containers/UniversalSearch/utils';
import { Icon, FormattedMessage as T } from '@/components';
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu';

export function DashboardTopbarSubscriptionMessage() {
  return (
    <div class="dashboard__topbar-subscription-msg">
      <span>
        <T id={'dashboard.subscription_msg.period_over'} />
      </span>
    </div>
  );
}

export function DashboardHamburgerButton({ ...props }) {
  return (
    <Button minimal={true} {...props}>
      {props.sidebarExpanded ? <LuChevronLeft size={20} /> : <LuChevronRight size={20} />}
    </Button>
  );
}

/**
 * Dashboard quick search button.
 */
export function DashboardQuickSearchButton({ ...rest }) {
  const searchTypeOptions = useGetUniversalSearchTypeOptions();

  // Can't continue if there is no any search type option.
  if (searchTypeOptions.length <= 0) {
    return null;
  }
  return (
    <Button
      className={Classes.MINIMAL}
      icon={<Icon icon={'search-24'} iconSize={20} />}
      // text={<T id={'quick_find'} />}
      {...rest}
    />
  );
}
